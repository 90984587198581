import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import theme from '../../../config/theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  position: fixed;
  top: 85%;
  right: 5%;
  width: 56px;
  cursor: pointer;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 50%;
  margin-bottom: 8px;
  width: 41px;
  height: 41px;
  background-color: ${({ theme }) => get(theme, 'white', '#fff')};
  border :2px solid ${({ theme }) => get(theme, 'blue', '#fff')};
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
`

const Text = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: bold;
  line-height: 1.14;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
`

const BackToTop = ({ isVisible }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  if (!isVisible) {
    return null
  }

  return (
    <Container onClick={scrollToTop}>
      <IconContainer>
        <Icon icon='chevron-up' color={get(theme, 'blue', '#fff')} />
      </IconContainer>
      <Text>Retour en haut</Text>
    </Container>
  )
}

BackToTop.propTypes = {
  isVisible: PropTypes.bool
}

export default BackToTop
