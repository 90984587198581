import React, { useCallback, useState, useEffect, useRef } from 'react'
import qs from 'qs'
import axios from 'axios'
import PropTypes from 'prop-types'
import { deserialize } from 'deserialize-json-api'
import { get, round, first, replace, map, isEqual, isUndefined, groupBy, reduce } from 'lodash'

import SEO from '../../atoms/Seo'
import JsonLD from '../../atoms/JsonLD'
import Navbar from '../../organisms/Navbar'
import Footer from '../../organisms/NewFooter'
import BackToTop from '../../atoms/BackToTop'
import CityIntro from '../../organisms/CityIntro'
import useOnScreen from '../../../hooks/useOnScreen'
import SiteSection from '../../atoms/SiteSection/'
import CityPrograms from '../../organisms/CityPrograms'
import CityVariables from '../../organisms/CityVariables'
import CityFirstPart from '../../organisms/CityFirstPart'
import CityTopSection from '../../organisms/CityTopSection'
import CityBuyWithFolhomee from '../../organisms/CityBuyWithFolhomee'
import CityPriceSquareMeter from '../../organisms/CityPriceSquareMeter'
import { API_SALES_URL } from '../../../config'
import { deepReplace, replaceGraphKey } from '../../../utils/deepReplace'
import { transformProgram, useSiteMetadata } from '../../../utils/seoTools'

const constructCities = (data, cityId) => {
  const grouped = groupBy(data, 'city_id')

  return reduce(grouped, (acc, cities, id) => {
    if (isEqual(cityId, id)) {
      return acc
    }

    return [...acc, ...cities]
  }, get(grouped, cityId, []))
}

const CityTemplate = ({ pageContext, location }) => {
  const [id, updateId] = useState(null)
  const [total, updateTotal] = useState(0)
  const [loading, updateLoading] = useState(true)
  const [programs, updatePrograms] = useState([])
  const ref = useRef()
  const isVisible = useOnScreen(ref)

  const city = get(pageContext, 'city', '')
  const insee = get(pageContext, 'insee', '')
  const region = get(pageContext, 'region', '')
  const zipcode = get(pageContext, 'zipcode', '')
  const textData = get(pageContext, 'textData', {})
  const department = get(pageContext, 'department', '')
  const departmentCode = get(pageContext, 'departmentCode', '')

  const search = qs.parse(replace(get(location, 'search'), '?', ''))
  const page = get(search, 'page')
  const [currentPage, updatePage] = useState(isUndefined(page) ? 1 : parseInt(page))

  const site = useSiteMetadata()
  const { webpage, organization } = deepReplace(site, replaceGraphKey)

  const title = `Programme Neuf - ${city} - ${zipcode}`
  const description = `Tous les appartements neufs à vendre à ${city} du 2 au 5 pièces à partir de 250000. Prix direct Promoteur. Terrasse, balcon, parking.`

  const fetchCity = useCallback(async () => {
    updateLoading(true)

    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/cities/search`, {
        params: {
          search: insee
        }
      })

      updateId((get(first(get(data, 'data')), 'id')))
    } catch (err) {
      console.log(err)
    } finally {
      updateLoading(false)
    }
  }, [updateLoading, city])

  const fetchCount = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/programs/count_search`, {
        params: {
          cities: [id],
          lotType: get(search, 'nature'),
          minRooms: get(search, 'minRooms'),
          maxRooms: get(search, 'maxRooms'),
          maxPrice: get(search, 'budget'),
          minSurface: get(search, 'surface'),
          deliveryYear: get(search, 'deliveryYear'),
          deliveryQuarter: get(search, 'deliveryQuarter')
        }
      })

      updateTotal(get(data, 'programs', 0))
    } catch (err) {
      console.log(err)
    }
  }, [id, search])

  const fetchPrograms = useCallback(async () => {
    updateLoading(true)

    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/programs/search`, {
        params: {
          cities: [id],
          lotType: get(search, 'nature'),
          minRooms: get(search, 'minRooms'),
          maxRooms: get(search, 'maxRooms'),
          maxPrice: get(search, 'budget'),
          minSurface: get(search, 'surface'),
          deliveryYear: get(search, 'deliveryYear'),
          deliveryQuarter: get(search, 'deliveryQuarter'),
          'page[number]': currentPage,
          'page[size]': 15
        }
      })

      updatePrograms(constructCities(get(deserialize(data), 'data'), id))
    } catch (err) {
      console.log(err)
    } finally {
      updateLoading(false)
    }
  }, [updateLoading, id, search, currentPage])

  useEffect(() => {
    fetchCity()
  }, [])

  useEffect(() => {
    if (id) {
      fetchCount()
      fetchPrograms()
    }
  }, [id, location])

  useEffect(() => {
    if (id) {
      fetchPrograms()
    }
  }, [id, currentPage])

  const seoPrograms = map(programs, program => transformProgram(program))
  const projectData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      itemListElement: seoPrograms
    }
  }

  return (
    <>
      <SEO
        title={title}
        location={location}
        description={description}>
        <JsonLD>
          {([webpage, organization, projectData])}
        </JsonLD>
      </SEO>
      <Navbar location={location} />
      <CityTopSection
        location={{
          type: 'city',
          city: [{
            id,
            name: city,
            insee,
            zipcode
          }],
          breadcrumb: {
            city,
            region,
            zipcode,
            department,
            departmentCode
          }
        }}
        loading={loading}
        programs={programs} />
      <section id={`${city} liste des programmes neufs`}>
        <CityPrograms
          city={city}
          page={currentPage}
          loading={loading}
          maxPage={round(total / 15)}
          programs={programs}
          updatePage={updatePage} />
      </section>
      <div ref={ref}>
        <BackToTop isVisible={isVisible} />
        <SiteSection id={`${city} introduction`}>
          <CityIntro
            city={city}
            image={get(textData, 'image')}
            programs={programs}
            {...get(textData, 'introduction')} />
        </SiteSection>
        <SiteSection id={`${city} les variables à étudier`}>
          <CityFirstPart
            city={city}
            variables={map(get(textData, 'variables'), variable => get(variable, 'entry.sentence_value'))}
            {...get(textData, 'first_part')} />
        </SiteSection>
        <CityVariables
          city={city}
          programs={programs}
          variables={get(textData, 'variables')} />
        <SiteSection id={`${city} prix au métre carré`}>
          <CityPriceSquareMeter
            city={city}
            programs={programs}
            {...get(textData, 'price_square_meter')} />
        </SiteSection>
        <SiteSection id={`${city} acheter avec Folhomee`}>
          <CityBuyWithFolhomee
            city={city}
            programs={programs}
            {...get(textData, 'buy_with_folhomee')} />
        </SiteSection>
      </div>
      <Footer
        noImage={true}
        noForm={true} />
    </>
  )
}

CityTemplate.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired
}

export default CityTemplate
