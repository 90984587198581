import React from 'react'
import PropTypes from 'prop-types'
import { map, split, includes, replace } from 'lodash'

import StyledList from '../../atoms/StyledList'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Sentence = ({ sentence }) => {
  if (includes(sentence, '\n')) {
    const items = split(sentence, '\n')

    return (
      <StyledList>
        {map(items, item => (
          <li key={item}>{replace(item, /"$/, '')}</li>
        ))}
      </StyledList>
    )
  }

  return (
    <HomeSectionDescription>
      {replace(sentence, /"$/, '')}
    </HomeSectionDescription>
  )
}

Sentence.propTypes = {
  sentence: PropTypes.string.isRequired
}

export default Sentence
