import { mapKeys, mapValues } from 'lodash'

const deepReplace = (obj, replaceFn) => {
  if (Array.isArray(obj)) {
    const res = []
    for (const item of obj) {
      res.push(deepReplace(item, replaceFn))
    }
    return res
  }
  if (typeof obj === 'object' && obj !== null) {
    const mappedKeys = mapKeys(obj, replaceFn)
    const res = mapValues(mappedKeys, (item) => deepReplace(item, replaceFn))
    return res
  }
  return obj
}

const replaceGraphKey = (_, key) => key.replace('_', '@')

export { deepReplace, replaceGraphKey }
