import { useState, useEffect, useRef } from 'react'

const useOnScreen = (ref) => {
  const observer = useRef()
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting))
    observer.current.observe(ref.current)

    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.current.disconnect()
    }
  }, [])

  return isIntersecting
}

export default useOnScreen
