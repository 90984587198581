import React from 'react'
import PropTypes from 'prop-types'
import { get, map, omit, split, join, initial, last, isEqual, size, first, replace } from 'lodash'

import Sentence from '../../atoms/Sentence'
import Container from '../../atoms/CityTextContainer'
import SiteSection from '../../atoms/SiteSection/'
import TextContainer from '../../atoms/CityTextDataContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import { replaceData } from '../../../utils/string'

const Paragraphs = ({ paragraphs, programs }) => {
  if (isEqual(size(paragraphs), 1)) {
    return (
      <HomeSectionDescription>
        {first(paragraphs)}.
      </HomeSectionDescription>
    )
  }

  return (
    <>
      <HomeSectionSubtitle>
        {replaceData(join(initial(paragraphs), '. '), programs)}.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        {replace(last(paragraphs), /"$/, '')}
      </HomeSectionDescription>
    </>
  )
}

Paragraphs.propTypes = {
  programs: PropTypes.array.isRequired,
  paragraphs: PropTypes.array.isRequired
}

const CityVariables = ({ variables, city, programs }) => map(variables, variable => {
  const paragraphs = split(get(variable, 'paragraph_1.sentence_value', ''), /[.?] /)

  return (
    <SiteSection
      id={`${city} ${get(variable, 'entry.sentence_value')}`}
      key={get(variable, 'entry.sentence_value', '')}>
      <Container>
        <TextContainer>
          <HomeSectionTitle tag='h2'>
            {get(variable, 'title.sentence_value', '')}
          </HomeSectionTitle>
          <Paragraphs programs={programs} paragraphs={paragraphs} />
          {map(omit(variable, ['title', 'paragraph_1', 'entry']), ({ sentence_value: sentence }) => (
            <Sentence key={get(sentence, 'sentence_value')} sentence={sentence} />
          ))}
        </TextContainer>
      </Container>
    </SiteSection>
  )
})

CityVariables.propTypes = {
  programs: PropTypes.array.isRequired,
  variables: PropTypes.array.isRequired
}

export default CityVariables
