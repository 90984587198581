import { get, map } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          webpage {
            _context
            _type
            name
            url
            relatedLink
            specialty
          }
          organization {
            _context
            _type
            _id
            url
            image
            geo {
              _type
              latitude
              longitude
            }
            address {
              _type
              streetAddress
              addressLocality
              postalCode
              addressCountry {
                _type
                name
              }
            }
            name
            knowsAbout
          }
        }
      }
    }
  `)
  return get(site, 'siteMetadata')
}

const getLotType = type => {
  switch (type) {
    case 'Appartement': case 'appartement':
      return 'apartment'
    case 'Maison': case 'maison':
      return 'house'
    default:
      return type
  }
}

const transformProgram = program => {
  const keywords = ['folhomee', get(program, 'name'), 'programme', 'neuf', 'immobilier', 'appartement', get(program, 'city.name')]

  const offerCatalog = map(get(program, 'lots'), lot => ({
    '@type': getLotType(get(lot, 'type')),
    name: `${get(lot, 'type')} neuf`,
    numberOfRooms: `${get(lot, 'rooms')} pièce${get(lot, 'rooms') === 1 ? '' : 's'}`,
    longitude: get(lot, 'address.lng'),
    latitude: get(lot, 'address.lat'),
    floorLevel: get(lot, 'floor'),
    floorSize: {
      '@type': 'QuantitativeValue',
      value: get(lot, 'surface'),
      unitCode: 'MTK'
    },
    offers: {
      '@type': 'Offer',
      price: get((get(lot, 'prices')[0], 'total')),
      priceCurrency: 'EUR'
    }
  }))

  return {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: get(program, 'name'),
    description: get(program, 'description'),
    keywords: keywords.join(', '),
    address: {
      '@type': 'PostalAddress',
      addressLocality: get(program, 'city.name'),
      postalCode: get(program, 'city.zipcode'),
      streetAddress: get(program, 'address.text')
    },
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      itemListElement: offerCatalog
    }
  }
}

export { getLotType, transformProgram, useSiteMetadata }
