import React from 'react'
import PropTypes from 'prop-types'
import { get, map } from 'lodash'

import Container from '../../atoms/CityTextContainer'
import StyledList from '../../atoms/StyledList'
import TextContainer from '../../atoms/CityTextDataContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const CityFirstPart = ({ city, title, subtitle, end, variables }) => (
  <Container>
    <TextContainer>
      <HomeSectionTitle tag='h2'>
        {get(title, 'sentence_value', '')}
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        {get(subtitle, 'sentence_value', '')}
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Parmi les variables qu’il est intéressant d’étudier avant de se décider à acheter figurent :
      </HomeSectionDescription>
      <StyledList>
        {map(variables, variable => (
          <li key={variable}>{variable}</li>
        ))}
      </StyledList>
      <HomeSectionDescription>
        {get(end, 'sentence_value', '')}
      </HomeSectionDescription>
    </TextContainer>
  </Container>
)

CityFirstPart.propTypes = {
  end: PropTypes.object.isRequired,
  city: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.object.isRequired,
  variables: PropTypes.array.isRequired
}

export default CityFirstPart
