import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { get, split, join, last, initial, isEqual, size, find, omit } from 'lodash'

import Sentence from '../../atoms/Sentence'
import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import { replaceData } from '../../../utils/string'

const IntroParagraphs = ({ subtitle }) => {
  const paragraphs = split(get(subtitle, 'sentence_value', ''), /[.?] /)

  if (isEqual(size(paragraphs), 1)) {
    return (
      <HomeSectionSubtitle>
        {get(subtitle, 'sentence_value', '')}
      </HomeSectionSubtitle>
    )
  }

  return (
    <>
      <HomeSectionSubtitle>
        {join(initial(paragraphs), '. ')}.
      </HomeSectionSubtitle>
      <Sentence sentence={last(paragraphs)} />
    </>
  )
}

IntroParagraphs.propTypes = {
  subtitle: PropTypes.object.isRequired
}

const CityIntro = ({ city, title, subtitle, image, programs }) => {
  const { placeholderImages } = useStaticQuery(graphql`{
    placeholderImages: allFile(
      filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "city"}}
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
    }
  }`)

  const imageData = find(get(placeholderImages, 'nodes'), ({ name }) => isEqual(name, image))

  return (
    <ImageSection
      right={true}
      imageData={omit(imageData, ['name'])}
      backgroundColor='paleGrey'>
        <HomeSectionTitle tag='h2'>
          {replaceData(get(title, 'sentence_value', ''), programs)}
        </HomeSectionTitle>
        <IntroParagraphs subtitle={subtitle} />
    </ImageSection>
  )
}

CityIntro.propTypes = {
  city: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  programs: PropTypes.array.isRequired,
  subtitle: PropTypes.object.isRequired
}

export default CityIntro
