import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import Container from '../../atoms/CityTextContainer'
import TextContainer from '../../atoms/CityTextDataContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import { replaceData } from '../../../utils/string'

const CityPriceSquareMeter = ({
  city, programs, title, paragraph_1: paragraphOne, paragraph_2: paragraphTwo, paragraph_3: paragraphThree
}) => (
  <Container>
    <TextContainer>
      <HomeSectionTitle tag='h2'>
        {replaceData(get(title, 'sentence_value', ''), programs)}
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        {replaceData(get(paragraphOne, 'sentence_value'), programs)}
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        {replaceData(get(paragraphTwo, 'sentence_value'), programs)}
      </HomeSectionDescription>
      <HomeSectionDescription>
        {replaceData(get(paragraphThree, 'sentence_value'), programs)}
      </HomeSectionDescription>
    </TextContainer>
  </Container>
)

CityPriceSquareMeter.propTypes = {
  city: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  programs: PropTypes.array.isRequired,
  paragraph_1: PropTypes.object.isRequired,
  paragraph_2: PropTypes.object.isRequired,
  paragraph_3: PropTypes.object.isRequired
}

export default CityPriceSquareMeter
